import {
    CountryCodeIso2,
    CreateStrategyReportRequestDto,
    SelectedAdFormatRequestDto,
} from '@api-clients/attention-data';
import { SelectedAdFormatResponseDto } from '@api-clients/attention-data/models/SelectedAdFormatResponseDto';
import { StrategyReportResponseDto } from '@api-clients/attention-data/models/StrategyReportResponseDto';
import { AgeGroup, Gender } from '@api-clients/media-plan';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import { CountrySelect, MultiSelect } from '@apps/attentionADJUST/components/molecules';
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    SimpleGrid,
    Stack,
    Text,
    useBoolean,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { AdFormatsReportsModal } from '@components/molecules/modals/adFormatsReportsModal/AdFormatsReportsModal';
import { routing } from '@configs';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useGetAllAdFormats } from '@hooks/adFormats/useGetAllAdFormats';
import { useCreateStrategyReport, useGetAllStrategyReports, useGetStrategyReport } from '@hooks/strategyReport';
import { useCustomToast } from '@hooks/toast';
import { useAppContextHelper } from '@hooks/_contexts';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { selectedGlobalCampaign } from '@redux/slices/global/campaign/selectors';
import { findCountryName } from '@shared/cores/types/Country';
import { useHelper } from '@shared/utils';
import {
    CountryFeatureCode,
    GetCountryCodeFromCountryFeature,
    isStrategyReportCountryFeature,
} from '@shared/utils/formats/countryFeatures';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateDraftStrategyReport } from '@hooks/strategyReport/useCreateDraftStrategyReport';
import { useDebouncedFunction } from '@hooks/debounce';
import { useUpdateDraftStrategyReport } from '@hooks/strategyReport/useUpdateDraftStrategyReport';
import { UpdateDraftStrategyReportRequestDto } from '@api-clients/attention-data/models/UpdateDraftStrategyReportRequestDto';
import { StrategyReportStatus } from '@api-clients/attention-data/models/StrategyReportStatus';
import { useDeleteStrategyReport } from '@hooks/strategyReport/useDeleteStrategyReport';

const HelperText: FC<{ description: string; isItalic?: boolean }> = ({
    description,
    isItalic = false,
}) => (
    <Text
        as={isItalic ? 'i' : undefined}
        color="gray.600"
        fontSize=".875rem"
        lineHeight="1.5"
        css={{ textAlign: 'left' }}
    >
        {description}
    </Text>
);

// Delay before saving user input
const AUTOSAVE_TIMER = 1000;
export const StrategyReportCreationPage: FC = () => {
    const emitCreateStrategyReportEvent = usePosthogEvent('Create a strategy report');
    const navigate = useNavigate();
    const { errorToast } = useCustomToast();
    const { strategyReportId, campaignId } = useParams();
    const { formatOptionsForSelect, formatAgeGroup, formatStringToCapitalized } = useHelper();

    const [selectedGenders, setSelectedGenders] = useState<Gender[]>(Object.values(Gender));
    const [selectedAgeGroups, setSelectedAgeGroups] = useState<AgeGroup[]>(Object.values(AgeGroup));
    const [selectedCountries, setSelectedCountries] = useState<CountryCodeIso2[]>([]);
    const [isUsingGlobal, setIsUsingGlobal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useBoolean(false);
    const [isFetching, setIsFetching] = useBoolean(false);
    const [isSaving, setIsSaving] = useBoolean(false);

    const [reportName, setReportName] = useState<string>('');
    const [createdStrategyReportId, setCreatedStrategyReportId] = useState<string>(
        strategyReportId || '',
    );
    const [strategyReportDetail, setStrategyReportDetail] = useState<StrategyReportResponseDto>();
    const { getStrategyReport } = useGetStrategyReport();
    const { createStrategyReport } = useCreateStrategyReport();
    const { createDraftStrategyReport } = useCreateDraftStrategyReport();
    const { updateDraftStrategyReport } = useUpdateDraftStrategyReport();
    const { deleteStrategyReport } = useDeleteStrategyReport();
    const { getAllStrategyReports } = useGetAllStrategyReports();

    const [strategyReportsFetched, setStrategyReportsFetched] = useBoolean(false);

    const [formatsData, setFormatsData] = useState<SelectedAdFormatResponseDto[]>();
    const { getAllAdFormats } = useGetAllAdFormats();

    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();
    const {
        isOpen: isAdFormatsModalOpen,
        onOpen: onAdFormatsModalOpen,
        onClose: onAdFormatsModalClose,
    } = useDisclosure();

    useEffect(() => {
        const fetchStrategyReports = async () => {
            try {
                const reports = await getAllStrategyReports();

                const draftReport = reports.find(
                    (report) =>
                        report.campaignId === campaignId &&
                        report.strategyReportStatus === StrategyReportStatus.Draft
                );
                if (draftReport) {
                    setCreatedStrategyReportId(draftReport.strategyReportId || '');
                }
            } finally {
                setStrategyReportsFetched.on();
            }
        };

        fetchStrategyReports();
    }, [campaignId, getAllStrategyReports]);

    // Determine if we should update or create the draft
    const handleSaveDraftStrategyReport = async () => {
        // Ensure the reports have been fetched before saving the draft
        if (!strategyReportsFetched) {
            return;
        }

        const payload: UpdateDraftStrategyReportRequestDto = {
            campaignId: campaignId!,
            name: reportName,
            selectedCountryCodes: selectedCountries,
            isUsingGlobalCountry: isUsingGlobal,
            selectedAgeGroups,
            selectedGenders,
            selectedFormatCodes: formatsData?.map(
                (f): SelectedAdFormatRequestDto => ({
                    adFormatCode: f.adFormatCode,
                    cpm: f.cpm,
                    isChecked: f.isChecked,
                }),
            ),
        };

        if (isSaving) return;
        setIsSaving.on();

        try {
            if (createdStrategyReportId) {
                await updateDraftStrategyReport(createdStrategyReportId, payload);
            } else {
                const newDraft = await createDraftStrategyReport(payload);
                setCreatedStrategyReportId(newDraft.strategyReportId || '');
            }
        } catch (e) {
            errorToast('Unable to save draft', 'An error occurred while saving the draft.');
        } finally {
            setIsSaving.off();
        }
    };

    const debouncedSaveDraft = useDebouncedFunction(handleSaveDraftStrategyReport, AUTOSAVE_TIMER);

    // Auto-save when input changes
    useEffect(() => {
        debouncedSaveDraft();
    }, [reportName, selectedCountries, selectedAgeGroups, selectedGenders]);

    // Save draft on page unload
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            handleSaveDraftStrategyReport();
            event.preventDefault();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [reportName, selectedCountries, selectedAgeGroups, selectedGenders]);

    useEffect(() => {
        const fetchAdFormats = async () => {
            const allAdFormats = await getAllAdFormats();
            setFormatsData(
                allAdFormats.map(
                    (f): SelectedAdFormatResponseDto => ({
                        adFormatCode: f.adFormatCode,
                        adFormatName: f.adFormatName,
                        adChannelName: f.channelName,
                        adChannelCode: f.channelCode,
                        isChecked: true,
                        cpm: 0,
                    }),
                ),
            );
        };
        fetchAdFormats();
    }, []);

    const { canUseGlobal, subscribedCountries } = useMemo(() => {
        const isGlobalFeatureBalance = featureBalances?.some(
            (f) =>
                f.featureCode === FeatureCode.StrategyReportAccessCountryGlobal &&
                f.limitType === LimitType.Unlimited,
        );
        setIsUsingGlobal(!!isGlobalFeatureBalance);
        return {
            canUseGlobal: isGlobalFeatureBalance,
            subscribedCountries:
                featureBalances
                    ?.filter(
                        (f) =>
                            isStrategyReportCountryFeature(f.featureCode!) &&
                            f.limitType === LimitType.Unlimited,
                    )
                    .map(
                        (f) =>
                            GetCountryCodeFromCountryFeature(
                                f.featureCode as CountryFeatureCode,
                            ) as CountryCodeIso2,
                    ) ?? [],
        };
    }, [featureBalances]);

    const handleClickOnGenerateStrategyReport = async () => {
        const payload: CreateStrategyReportRequestDto = {
            campaignId: campaignId!,
            name: reportName,
            selectedCountryCodes: [...selectedCountries],
            selectedAgeGroups,
            selectedGenders,
            selectedFormatCodes: formatsData?.map(
                (f): SelectedAdFormatRequestDto => ({
                    adFormatCode: f.adFormatCode,
                    cpm: f.cpm,
                    isChecked: f.isChecked,
                }),
            ),
            isUsingGlobalCountry: isUsingGlobal,
        };

        try {
            setIsLoading.on();

            // Delete the previous draft strategy report if it exists
            if (createdStrategyReportId) {
                // Fetch the existing strategy report details
                const existingReport = await getStrategyReport({ strategyReportId: createdStrategyReportId });

                // Check if the existing report is in draft status
                if (existingReport.strategyReportStatus === StrategyReportStatus.Draft) {
                    await deleteStrategyReport(createdStrategyReportId);
                }
            }

            const response = await createStrategyReport(payload);
            setCreatedStrategyReportId(response.strategyReportId || '');
            navigate(
                `/${routing.campaign.root.path}/${campaignId}/${routing.strategyReports.root.path}/${routing.strategyReports.view.path}/${response.strategyReportId}`,
            );
        } catch (e) {
            errorToast(
                'Unable to create strategy report',
                'Strategy report exists for this campaign',
            );
        } finally {
            setIsLoading.off();
        }
    };

    useEffect(() => {
        const fetchStrategyReport = async (id: string) => {
            setIsFetching.on();
            try {
                const report = await getStrategyReport({ strategyReportId: id });
                setStrategyReportDetail(report);
                setReportName(report.name || '');
                setIsUsingGlobal(report.isUsingGlobalCountry || false);
                if (!report.isUsingGlobalCountry) {
                    setSelectedCountries(report.selectedCountryCodes || []);
                } else {
                    setSelectedCountries([]);
                }
                setSelectedAgeGroups(
                    report.selectedAgeGroups && report.selectedAgeGroups.length > 0
                        ? report.selectedAgeGroups
                        : Object.values(AgeGroup)
                );
                setSelectedGenders(
                    report.selectedGenders && report.selectedGenders.length > 0
                        ? report.selectedGenders
                        : Object.values(Gender)
                );

                if (report.selectedFormats) {
                    setFormatsData(report.selectedFormats);
                }
            } catch (e) {
                errorToast('Unable to fetch strategy report', 'Strategy report does not exist');
            } finally {
                setIsFetching.off();
            }
        };

        if (createdStrategyReportId) {
            fetchStrategyReport(createdStrategyReportId);
        }
    }, [createdStrategyReportId]);

    const handleSaveAndRedirect = async () => {
        try {
            await handleSaveDraftStrategyReport();
            // Only navigate if the draft was successfully saved
            navigate(`/${routing.campaign.root.path}`);
        } catch (error) {
            console.error('Error occurred during draft save and redirect:', error);
        }
    };

    const toast = useToast();

    const selectedGlobalCampaignSelector = useSelector(selectedGlobalCampaign);

    const {
        helper: { selectGlobalCampaign },
    } = useAppContextHelper();

    useEffect(() => {
        if (!campaignId) {
            return;
        }

        selectGlobalCampaign(campaignId).catch();
    }, [campaignId]);

    useEffect(() => {
        if (selectedGlobalCampaignSelector) {
            setReportName(selectedGlobalCampaignSelector.name);
        }
    }, [selectedGlobalCampaignSelector]);

    return (
        <Container>
            <Stack>
                <ContainerTitle
                    headingText="Create strategy report"
                    subtitleText="All the information below will be used to calculate attention data and generate
                    recommendations for your campaign"
                />
                <AsyncCard>
                    <Stack>
                        <SimpleGrid columns={3} spacing=".5rem">
                            <FormControl>
                                <FormLabel>Strategy report name</FormLabel>
                                <Input
                                    placeholder={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    name="reportName"
                                    value={reportName}
                                />
                            </FormControl>
                        </SimpleGrid>

                        <Flex flexDir="column" gap=".5rem">
                            <SimpleGrid columns={3} spacing=".5rem">
                                <CountrySelect
                                    label="Countries"
                                    setSelectedOptions={(selectedOptions) =>
                                        setSelectedCountries(
                                            selectedOptions.map((o) => o.value as CountryCodeIso2),
                                        )
                                    }
                                    selectedOptions={formatOptionsForSelect(
                                        selectedCountries,
                                        findCountryName,
                                    )}
                                    isRequired
                                    canSelectGlobal={!!canUseGlobal}
                                    isUsingGlobal={!!isUsingGlobal}
                                    setIsUsingGlobal={() => setIsUsingGlobal(!isUsingGlobal)}
                                    availableCountryOptions={subscribedCountries}
                                />
                                <MultiSelect
                                    label="Age group"
                                    options={formatOptionsForSelect(
                                        Object.values(AgeGroup),
                                        formatAgeGroup,
                                    ).map((o) => ({
                                        isDisabled: false,
                                        showLockIcon: false,
                                        ...o,
                                    }))}
                                    setSelectedOptions={(selectedOptions) =>
                                        setSelectedAgeGroups(
                                            selectedOptions.map((o) => o.value as AgeGroup),
                                        )
                                    }
                                    selectedOptions={formatOptionsForSelect(
                                        selectedAgeGroups,
                                        formatAgeGroup,
                                    )}
                                    isRequired
                                    isClearable
                                    selectAll
                                    allowSelectAll
                                />
                                <MultiSelect
                                    label="Gender"
                                    options={formatOptionsForSelect(
                                        Object.values(Gender),
                                        formatStringToCapitalized,
                                    )}
                                    setSelectedOptions={(selectedOptions) =>
                                        setSelectedGenders(
                                            selectedOptions.map((o) => o.value as Gender),
                                        )
                                    }
                                    selectedOptions={formatOptionsForSelect(
                                        selectedGenders,
                                        formatStringToCapitalized,
                                    )}
                                    isRequired
                                    isClearable
                                    selectAll
                                    allowSelectAll
                                />
                            </SimpleGrid>
                            <HelperText description="All attention data will be calculated for your selected target audience. Demographics can't be changed after creating a strategy report." />
                        </Flex>

                        <Flex flexDir="column" gap=".5rem">
                            <Box>
                                <FormLabel>Formats</FormLabel>
                                <SimpleGrid columns={3} spacing=".5rem">
                                    <Button
                                        isLoading={!formatsData}
                                        loadingText="Configure formats"
                                        variant="outline"
                                        onClick={onAdFormatsModalOpen}
                                        leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
                                        colorScheme="orange"
                                        w="100%"
                                        fontSize="1rem"
                                    >
                                        Configure formats
                                    </Button>
                                </SimpleGrid>
                            </Box>
                            <HelperText
                                description="Selected channels and formats' performance will be viewed
                                    against all of our available channels and formats. Formats and
                                    CPMs can be updated after creating a strategy report."
                            />
                        </Flex>
                        {isLoading && (
                            <HelperText
                                isItalic
                                description="Generating your strategy report — this may take up to 1 minute."
                            />
                        )}

                        <SimpleGrid columns={3} spacing=".5rem">
                            <Flex gap=".75rem" alignItems="center" w="100%">
                                <Button
                                    variant="outline"
                                    fontSize="1rem"
                                    padding="0.625rem 1.25rem"
                                    onClick={() => {
                                        handleSaveAndRedirect();
                                    }}
                                    flex="1 1 auto"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    flex="3 3 auto"
                                    onClick={() => {
                                        emitCreateStrategyReportEvent();
                                        handleClickOnGenerateStrategyReport().catch();
                                    }}
                                    fontSize="1rem"
                                    padding="0.625rem 1.25rem"
                                    isLoading={isLoading}
                                    loadingText="Generating..."
                                    isDisabled={
                                        !formatsData ||
                                        selectedAgeGroups.length === 0 ||
                                        selectedGenders.length === 0 ||
                                        (selectedCountries.length === 0 && !isUsingGlobal) ||
                                        reportName.trim() === ''
                                    }
                                >
                                    Generate strategy report
                                </Button>
                            </Flex>
                        </SimpleGrid>
                    </Stack>
                </AsyncCard>

                {formatsData && (
                    <AdFormatsReportsModal
                        selectedAdFormats={formatsData}
                        onConfirm={(adFormats) => {
                            setFormatsData(adFormats);
                            onAdFormatsModalClose();
                            toast({
                                title: `Ad formats changed`,
                                description: `Ad formats modified`,
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            });
                        }}
                        onCancel={onAdFormatsModalClose}
                        isOpen={isAdFormatsModalOpen}
                    />
                )}
            </Stack>
        </Container>
    );
};