import { StrategyReportResponseDto } from '@api-clients/attention-data';
import {
    StrategyReportsApi,
    CreateADraftStrategyReportRequest
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { CreateStrategyReportRequestDto } from '@api-clients/attention-data/models/CreateStrategyReportRequestDto';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface useCreateDraftStrategyReportProps {
    createDraftStrategyReport: (
        CreateStrategyReportRequestDto: CreateStrategyReportRequestDto,
    ) => Promise<StrategyReportResponseDto>;
}

type useCreateDraftStrategyReportHook = () => useCreateDraftStrategyReportProps;

export const useCreateDraftStrategyReport: useCreateDraftStrategyReportHook = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function createDraftStrategyReport(
        createStrategyReportRequestDto: CreateStrategyReportRequestDto,
    ): Promise<StrategyReportResponseDto> {
        try {
            const config = await getAttentionDataApiConfig();
            const request: CreateADraftStrategyReportRequest = {
                createStrategyReportRequestDto,
            };

            const api = new StrategyReportsApi(config);

            return await api.createADraftStrategyReport(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        createDraftStrategyReport,
    };
};