import { ChannelSegmentResponseDto } from '@api-clients/attention-data';
import { getChartAxisRange, segmentGraphNumberFormatter } from '@apps/attention360/pages';
import {
    FlameColorCategory,
    flameColors,
    getBubbleColor,
} from '@apps/attention360/pages/segment/BubbleColors';
import {
    getFlameGraphData,
    graphViewAvailableSelectOptions,
    GraphViewOption,
    segmentMapping,
} from '@apps/attention360/pages/segment/SegmentUtil';
import {
    Box,
    Button,
    Card,
    Flex,
    Icon,
    Select,
    Spinner,
    Text,
    Tooltip as ChakraTooltip,
    useBoolean,
    useDisclosure,
} from '@chakra-ui/react';
import {
    HELP_SECTION_CONTENT,
    SEGMENT_OPTIONS,
    SegmentOptionValue,
} from '@apps/attention360/pages/strategyReports/StrategyReportUtil';
import { SegmentDividerPluginFactory, WhiteBackgroundPlugin } from '@components/Chart';
import { useGetAllSegments } from '@hooks/strategyReport';
import { useCustomToast } from '@hooks/toast';
import {
    getCeilNearestTen,
    getCeilNearestTenth,
    getFloorNearestTen,
    getFloorNearestTenth,
    getMax,
    getMedian,
    getMin,
} from '@shared/utils/mathUtils';
import {
    Chart as ChartJS,
    ChartData,
    Legend,
    LinearScale,
    Plugin,
    PointElement,
    ChartEvent,
    ChartConfiguration,
    Tooltip,
    ActiveElement,
} from 'chart.js';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Bubble } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { colors } from '@assets/design-system/theming/colors';
import { throttle } from '@shared/utils';
import { useStrategyReportContext } from '../strategyReports/StrategyReportContextProvider';
import { HelpSection } from '../strategyReports/HelpSection';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const axisTitleColor = '#414141';
const xAxisTitleText = 'Retention rate';
const yAxisTitleText = 'Attentive reach (%)';
const graphHeight = '56rem';
const bubbleRadius = 7;
type SegmentGraphProps = {
    showOnlySelectedFormat: boolean;
};
type Content = {
    description: string[];
    campaignObjective: string[];
    attentionPattern: string[];
    brandSize: string[];
    buyingStrategy: string[];
    recommendedCreativeContent: string[];
};

export const SegmentGraph = forwardRef<
    ChartJSOrUndefined<'bubble', { x: number; y: number; r: number; label: string; cpm: number }[]>,
    SegmentGraphProps
>(({ showOnlySelectedFormat }, ref) => {
    const [graphViewOption, setGraphViewOptions] = useState<GraphViewOption>(
        graphViewAvailableSelectOptions[0].value,
    );
    const [selectedSegment, setSelectedSegment] = useState<SegmentOptionValue>('all');
    const { getAllSegments } = useGetAllSegments();
    const { errorToast } = useCustomToast();
    const [isLoadingSegments, setIsLoadingSegments] = useBoolean(false);
    const [segments, setSegments] = useState<ChannelSegmentResponseDto[]>([]);
    const { strategyReport } = useStrategyReportContext();
    const fetchSegments = async () => {
        try {
            setIsLoadingSegments.on();
            const result = await getAllSegments({
                strategyReportId: strategyReport.strategyReportId!,
            });
            setSegments(result);
        } catch (e: any) {
            errorToast('Oops', 'Something went wrong!');
        } finally {
            setIsLoadingSegments.off();
        }
    };
    const chartWrapper = useRef<HTMLDivElement>(null);
    const xAxisTitleIconRef = useRef<HTMLElement>(null);
    const yAxisTitleIconRef = useRef<HTMLElement>(null);
    const topRightTooltipRef = useRef<HTMLDivElement>(null);
    const topLeftTooltipRef = useRef<HTMLDivElement>(null);
    const bottomRightTooltipRef = useRef<HTMLDivElement>(null);
    const bottomLeftTooltipRef = useRef<HTMLDivElement>(null);
    const [tooltipOpen, setTooltipOpen] = useState({
        topLeft: false,
        topRight: false,
        bottomLeft: false,
        bottomRight: false,
    });
    const tooltipText =
        selectedSegment === 'all' ? 'Click to learn more' : 'Click again to zoom out';
    useEffect(() => {
        fetchSegments().finally();
    }, [strategyReport]);
    const data: ChartData<
        'bubble',
        { x: number; y: number; r: number; label: string; cpm: number }[]
    > = {
        datasets: segments.map((ch, i) => {
            const formatLevelData: {
                x: number;
                y: number;
                r: number;
                label: string;
                isSelected: boolean;
                cpm: number;
            }[] =
                ch.formatSegmentResponseDto?.map((f) => ({
                    x: f.retentionRate ?? 0,
                    y: (f.attentiveReachPercent ?? 0) * 100,
                    r: bubbleRadius,
                    label: `${ch.adChannelName} - ${f.adFormatName}`.trim(),
                    isSelected: f.isSelected ?? false,
                    cpm: f.cpm ?? 0,
                })) ?? [];
            // if the ad channel has at least one selected format, then it is a selected channel
            const isChSelected = formatLevelData.find((f) => f.isSelected) !== undefined;
            return {
                label: ch.adChannelName ?? '',
                // selected channels are a solid bubble, unselected channels are rings
                backgroundColor: isChSelected ? getBubbleColor(i) : 'rgba(0,0,0,0)',
                borderColor: getBubbleColor(i),
                borderWidth: 2,
                data: showOnlySelectedFormat
                    ? formatLevelData.filter((f) => f.isSelected)
                    : formatLevelData,
            };
        }),
    };

    const formatLevelData = getFlameGraphData(segments);
    const cpmData: ChartData<
        'bubble',
        { x: number; y: number; r: number; label: string; cpm: number }[]
    > = {
        datasets: Object.keys(formatLevelData).map((k) => {
            const category = formatLevelData[k as FlameColorCategory];
            return {
                label: category.label,
                backgroundColor: flameColors[k as FlameColorCategory],
                borderColor: flameColors[k as FlameColorCategory],
                borderWidth: 2,
                data: category.data.map((p) => ({
                    x: p.retentionRate ?? 0,
                    y: 100 * (p.attentiveReachPercent ?? 0),
                    r: bubbleRadius,
                    label: `${p.adChannelName ?? ''} - ${p.adFormatName ?? ''}`.trim(),
                    cpm: p.cpm ?? 0,
                })),
            };
        }),
    };
    const dataSrc = graphViewOption === 'channel' ? data : cpmData;
    const formatSegmentsResponseDtos = segments.flatMap((ch) => ch.formatSegmentResponseDto ?? []);
    const xAxisAvg = getMedian(formatSegmentsResponseDtos, (dto) => dto.retentionRate ?? 0);
    const yAxisAvg =
        100 * getMedian(formatSegmentsResponseDtos, (dto) => dto.attentiveReachPercent ?? 0);
    const xAxisMin =
        segments.length === 0
            ? 0
            : getFloorNearestTenth(
                  getMin(formatSegmentsResponseDtos.map((f) => f.retentionRate ?? 0)),
              );
    const xAxisMax =
        segments.length === 0
            ? 1
            : getCeilNearestTenth(
                  getMax(formatSegmentsResponseDtos.map((f) => f.retentionRate ?? 0)),
              );
    const yAxisMin =
        segments.length === 0
            ? 0
            : getFloorNearestTen(
                  getMin(
                      formatSegmentsResponseDtos.map((f) => (f.attentiveReachPercent ?? 0) * 100),
                  ),
              );
    const yAxisMax =
        segments.length === 0
            ? 100
            : getCeilNearestTen(
                  getMax(
                      formatSegmentsResponseDtos.map((f) => (f.attentiveReachPercent ?? 0) * 100),
                  ),
              );

    const setChartBackgroundPlugin: Plugin<'bubble'> = {
        id: 'setBackgroundToWhitePlugin',
        afterInit: WhiteBackgroundPlugin,
    };
    const addDividerPlugin: Plugin<'bubble'> = {
        id: 'addDividerPlugin',
        afterDatasetsDraw: SegmentDividerPluginFactory(xAxisAvg, yAxisAvg),
    };

    const addIconToAxisTitlePlugin: Plugin<'bubble'> = {
        id: 'addIconToAxisTitlePlugin',
        afterDraw: throttle((chart: ChartJS) => {
            const { ctx } = chart;
            const xAxis = chart.scales.x;
            const yAxis = chart.scales.y;
            const xAxisTitle: string | undefined = (xAxis.options as any)?.title?.text;
            const yAxisTitle: string | undefined = (yAxis.options as any)?.title?.text;
            if (xAxisTitle) {
                const xAxisTitleMetrics = ctx.measureText(xAxisTitle);
                const xAxisTitleIconLeft =
                    chart.canvas.width / 2 / window.devicePixelRatio +
                    90 +
                    xAxisTitleMetrics.width / 2;
                const xAxisTitleIconBottom =
                    32 +
                    (chart?.legend?.height ?? 0) +
                    xAxisTitleMetrics.fontBoundingBoxDescent +
                    xAxisTitleMetrics.fontBoundingBoxAscent;

                if (xAxisTitleIconRef.current) {
                    const titleIcon = xAxisTitleIconRef.current;
                    titleIcon.style.left = `${xAxisTitleIconLeft}`;
                    titleIcon.style.bottom = `${xAxisTitleIconBottom}`;
                    titleIcon.style.opacity = '1';
                }
            }
            if (yAxisTitle) {
                const yAxisTitleMetrics = ctx.measureText(yAxisTitle);
                const yAxisTitleIconLeft =
                    32 +
                    yAxisTitleMetrics.fontBoundingBoxDescent +
                    yAxisTitleMetrics.fontBoundingBoxAscent;
                const yAxisTitleIconTop =
                    chart.canvas.height / 2 / window.devicePixelRatio -
                    yAxisTitleMetrics.width / 2 -
                    41;
                if (yAxisTitleIconRef.current) {
                    const titleIcon = yAxisTitleIconRef.current;
                    titleIcon.style.left = `${yAxisTitleIconLeft}`;
                    titleIcon.style.top = `${yAxisTitleIconTop}`;
                    titleIcon.style.opacity = '1';
                }
            }
        }, 1000),
    };
    const { displayedXAxisMin, displayedXAxisMax, displayedYAxisMin, displayedYAxisMax } =
        getChartAxisRange({
            xAxisMin,
            xAxisAvg,
            xAxisMax,
            yAxisMin,
            yAxisAvg,
            yAxisMax,
            selectedSegment,
        });

    // State and disclosure hook for the HelpSection
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [helpTitle, setHelpTitle] = useState('');
    const [helpContent, setHelpContent] = useState<Content>({
        description: [],
        campaignObjective: [],
        attentionPattern: [],
        brandSize: [],
        buyingStrategy: [],
        recommendedCreativeContent: [],
    });

    const chartOptions: ChartConfiguration<'bubble'>['options'] = {
        onClick: (event: ChartEvent, elements: ActiveElement[], chart) => {
            if (elements.length !== 0 || event.type !== 'click') {
                // user did not click on a blank place
                return;
            }
            const { x, y } = event;
            if (!x || !y) {
                return;
            }
            const { top, left, width, height } = chart.chartArea;
            const { scales } = chart;

            // Calculate division boundaries based on xAxisAvg and yAxisAvg values
            const divisionX = scales.x.getPixelForValue(xAxisAvg);
            const divisionY = scales.y.getPixelForValue(yAxisAvg);

            if (selectedSegment === 'all') {
                if (x >= left && x <= divisionX && y >= top && y <= divisionY) {
                    setSelectedSegment('fourth');
                    onOpen();
                    setHelpTitle(HELP_SECTION_CONTENT[3].heading);
                    setHelpContent(HELP_SECTION_CONTENT[3].content);
                } else if (x > divisionX && x <= left + width && y >= top && y <= divisionY) {
                    setSelectedSegment('first');
                    onOpen();
                    setHelpTitle(HELP_SECTION_CONTENT[0].heading);
                    setHelpContent(HELP_SECTION_CONTENT[0].content);
                } else if (x >= left && x <= divisionX && y > divisionY && y <= top + height) {
                    setSelectedSegment('third');
                    onOpen();
                    setHelpTitle(HELP_SECTION_CONTENT[2].heading);
                    setHelpContent(HELP_SECTION_CONTENT[2].content);
                } else if (
                    x > divisionX &&
                    x <= left + width &&
                    y > divisionY &&
                    y <= top + height
                ) {
                    setSelectedSegment('second');
                    onOpen();
                    setHelpTitle(HELP_SECTION_CONTENT[1].heading);
                    setHelpContent(HELP_SECTION_CONTENT[1].content);
                }
            } else {
                setSelectedSegment('all');
            }
        },
        clip: bubbleRadius,
        scales: {
            x: {
                title: {
                    display: true,
                    text: xAxisTitleText,
                    color: axisTitleColor,
                    font: {
                        size: 16,
                    },
                },
                min: displayedXAxisMin,
                max: displayedXAxisMax,
            },
            y: {
                title: {
                    display: true,
                    text: yAxisTitleText,
                    color: axisTitleColor,
                    font: {
                        size: 16,
                    },
                },
                min: displayedYAxisMin,
                max: displayedYAxisMax,
            },
        },
        plugins: {
            legend:
                graphViewOption === 'channel'
                    ? {
                          display: true,
                      }
                    : {
                          display: true,
                          labels: {
                              padding: 40,
                              boxWidth: 40,
                              boxHeight: 30,
                              usePointStyle: false,
                          },
                      },
            title: {
                display: true,
                text: 'Attention segments',
                color: '#1A1A1A',
                font: {
                    size: 22,
                    weight: 'bold',
                },
                padding: {
                    top: 10,
                    bottom: 40,
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    title: (tooltipItems) => {
                        const item = tooltipItems.at(0);
                        if (!item) return '';
                        const dataSource = graphViewOption === 'channel' ? data : cpmData;
                        return dataSource.datasets[item.datasetIndex].data[item.dataIndex].label;
                    },
                    label: (tooltipItem) => {
                        const dataSource = graphViewOption === 'channel' ? data : cpmData;
                        const dataPoint =
                            dataSource.datasets[tooltipItem.datasetIndex].data[
                                tooltipItem.dataIndex
                            ];
                        return [
                            `Retention Rate: ${segmentGraphNumberFormatter.format(dataPoint.x)}`,
                            `Attentive Reach: ${segmentGraphNumberFormatter.format(dataPoint.y)}%`,
                            `CPM: ${segmentGraphNumberFormatter.format(dataPoint.cpm)}`,
                        ];
                    },
                },
            },
        },
    };

    const divisionHoverPlugin: Plugin<'bubble'> = {
        id: 'divisionHoverPlugin',
        beforeInit: (chart) => {
            (chart as any).lastHoveredDivision = null;
        },
        afterEvent: throttle((chart: ChartJS, args: any) => {
            const event = args.event as ChartEvent;
            const { ctx } = chart;
            const { scales } = chart;

            if (event.type === 'mousemove') {
                const { x, y } = event;
                if (x === null || y === null) return;
                const { top, left, width, height } = chart.chartArea;

                const divisionX = scales.x.getPixelForValue(xAxisAvg);
                const divisionY = scales.y.getPixelForValue(yAxisAvg);

                ctx.save();
                ctx.font = 'bold 22px Inter';
                const titleTextMetric = ctx.measureText('Attention segments');
                const titleHeight =
                    titleTextMetric.actualBoundingBoxAscent +
                    titleTextMetric.actualBoundingBoxDescent;
                ctx.restore();
                ctx.save();
                ctx.strokeStyle = '#00AC59';
                ctx.lineWidth = 0.1;

                // Determine if the mouse is within a division
                if (x >= left && x <= divisionX && y >= top && y <= divisionY) {
                    chart.canvas.style.cursor = 'pointer';
                    const tooltipElm = topLeftTooltipRef.current;
                    if (tooltipElm) {
                        tooltipElm.style.top = `${32 + 50 + titleHeight}px`;
                        setTooltipOpen({
                            topRight: false,
                            topLeft: true,
                            bottomLeft: false,
                            bottomRight: false,
                        });
                    }
                } else if (x > divisionX && x <= left + width && y >= top && y <= divisionY) {
                    chart.canvas.style.cursor = 'pointer';
                    const tooltipElm = topRightTooltipRef.current;
                    if (tooltipElm) {
                        tooltipElm.style.top = `${32 + 50 + titleHeight}px`;
                        setTooltipOpen({
                            topRight: true,
                            topLeft: false,
                            bottomLeft: false,
                            bottomRight: false,
                        });
                    }
                } else if (x >= left && x <= divisionX && y > divisionY && y <= top + height) {
                    chart.canvas.style.cursor = 'pointer';
                    const tooltipElm = bottomLeftTooltipRef.current;
                    if (tooltipElm) {
                        tooltipElm.style.top = `${32 + 50 + titleHeight + height}px`;
                        setTooltipOpen({
                            topRight: false,
                            topLeft: false,
                            bottomLeft: true,
                            bottomRight: false,
                        });
                    }
                } else if (
                    x > divisionX &&
                    x <= left + width &&
                    y > divisionY &&
                    y <= top + height
                ) {
                    chart.canvas.style.cursor = 'pointer';

                    const tooltipElm = bottomRightTooltipRef.current;
                    if (tooltipElm) {
                        tooltipElm.style.top = `${32 + 50 + titleHeight + height}px`;
                        setTooltipOpen({
                            topRight: false,
                            topLeft: false,
                            bottomLeft: false,
                            bottomRight: true,
                        });
                    }
                } else {
                    // not in the chart
                    setTooltipOpen({
                        topRight: false,
                        topLeft: false,
                        bottomLeft: false,
                        bottomRight: false,
                    });
                    chart.canvas.style.cursor = 'default';
                }
                ctx.restore();
            }
        }, 400),
    };

    const chartPlugins: ChartConfiguration<'bubble'>['plugins'] = [
        setChartBackgroundPlugin,
        addDividerPlugin,
        divisionHoverPlugin,
        addIconToAxisTitlePlugin,
    ];

    const segmentKey = selectedSegment === 'all' ? 'all' : selectedSegment;
    const segmentGroups = segmentMapping[segmentKey] || [];

    // Determine bottom value based on graphViewOption
    const bottomValue =
        graphViewOption === graphViewAvailableSelectOptions[1].value ? '11.5rem' : '10rem';

    return (
        <Card position="relative" p="2rem">
            {formatSegmentsResponseDtos.length > 0 && (
                <>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text color="gray.600" fontSize=".875rem" lineHeight="1rem">
                            Click a segment to learn how to utilise formats for your campaign
                        </Text>
                        <Flex alignItems="center" gap=".5rem">
                            <Text
                                color="gray.600"
                                fontSize=".875rem"
                                fontWeight={500}
                                lineHeight="1rem"
                            >
                                Chart&nbsp;display
                            </Text>
                            <Select
                                value={graphViewOption}
                                onChange={(e) =>
                                    setGraphViewOptions(e.target.value as GraphViewOption)
                                }
                            >
                                {graphViewAvailableSelectOptions.map((option) => (
                                    <option value={option.value} key={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>
                    <Box
                        height={graphHeight}
                        bg="white"
                        p="2rem"
                        position="relative"
                        ref={chartWrapper}
                    >
                        <Bubble
                            data={dataSrc}
                            options={chartOptions}
                            plugins={chartPlugins}
                            ref={ref}
                        />
                        <ChakraTooltip
                            label={tooltipText}
                            isOpen={tooltipOpen.topRight}
                            placement="top"
                            hasArrow
                        >
                            <Box
                                width="1px"
                                height="1px"
                                opacity="0"
                                position="absolute"
                                top="0px"
                                left="85%"
                                ref={topRightTooltipRef}
                            />
                        </ChakraTooltip>
                        <ChakraTooltip
                            isOpen={tooltipOpen.topLeft}
                            label={tooltipText}
                            placement="top"
                            hasArrow
                        >
                            <Box
                                width="1px"
                                height="1px"
                                opacity="0"
                                position="absolute"
                                top="0px"
                                left="15%"
                                ref={topLeftTooltipRef}
                            />
                        </ChakraTooltip>
                        <ChakraTooltip isOpen={tooltipOpen.bottomLeft} label={tooltipText} hasArrow>
                            <Box
                                width="1px"
                                height="1px"
                                opacity="0"
                                position="absolute"
                                top="0px"
                                left="15%"
                                ref={bottomLeftTooltipRef}
                            />
                        </ChakraTooltip>
                        <ChakraTooltip
                            isOpen={tooltipOpen.bottomRight}
                            label={tooltipText}
                            hasArrow
                        >
                            <Box
                                width="1px"
                                height="1px"
                                opacity="0"
                                position="absolute"
                                top="0px"
                                left="85%"
                                ref={bottomRightTooltipRef}
                            />
                        </ChakraTooltip>
                        {segmentGroups.map((segment, index) => (
                            <Text
                                key={index}
                                position="absolute"
                                align={segment.align}
                                top={segment.top}
                                bottom={bottomValue}
                                left={segment.left}
                                right={segment.right}
                                fontSize=".875rem"
                                fontWeight={600}
                                color={colors.colors.gray['700']}
                                opacity={0.75}
                                pointerEvents="none"
                            >
                                {segment.text1}
                                <br />
                                {segment.text2}
                                {segment.nestedText && (
                                    <Text
                                        fontSize={segment.nestedText.fontSize}
                                        fontWeight={segment.nestedText.fontWeight}
                                        color={segment.nestedText.color}
                                        opacity={segment.nestedText.opacity}
                                    >
                                        {segment.nestedText.text1}
                                        <br />
                                        {segment.nestedText.text2}
                                    </Text>
                                )}
                            </Text>
                        ))}
                        {selectedSegment !== 'all' && (
                            <Box position="absolute" top="2rem" left="1rem">
                                <ChakraTooltip label="Zoom out" placement="top">
                                    <Button
                                        aria-label="zoom out"
                                        color="gray.700"
                                        colorScheme="gray"
                                        type="button"
                                        onClick={() => setSelectedSegment('all')}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Icon as={ArrowLeftIcon} boxSize="1rem" />
                                    </Button>
                                </ChakraTooltip>
                            </Box>
                        )}
                        <ChakraTooltip
                            label="How well attention retains over the viewing session (0 - 1). 1 means viewers pay the same amount of attention at the end of the ad as the start."
                            placement="top"
                            hasArrow
                        >
                            <Icon
                                opacity={0}
                                as={InfoOutlineIcon}
                                boxSize="1rem"
                                ref={xAxisTitleIconRef}
                                position="absolute"
                                left={0}
                                bottom={0}
                            />
                        </ChakraTooltip>
                        <ChakraTooltip
                            label="The audience percentage that paid at least 1 second of active attention"
                            placement="right"
                            hasArrow
                        >
                            <Icon
                                opacity={0}
                                as={InfoOutlineIcon}
                                boxSize="1rem"
                                ref={yAxisTitleIconRef}
                                position="absolute"
                                left={0}
                                top={0}
                                transform="rotate(-90deg)"
                            />
                        </ChakraTooltip>
                    </Box>
                </>
            )}
            {isLoadingSegments && (
                <Box
                    display="grid"
                    placeItems="center"
                    position="absolute"
                    bg="white"
                    opacity={0.7}
                    inset={0}
                    height={graphHeight}
                >
                    <Spinner size="xl" speed=".6s" thickness="4px" />
                </Box>
            )}
            {/* HelpSection Side Menu */}
            <HelpSection
                isOpen={isOpen}
                onClose={onClose}
                title={helpTitle}
                content={helpContent}
            />
        </Card>
    );
});
