/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { StrategyReportStatus } from './StrategyReportStatus';
import {
    StrategyReportStatusFromJSON,
    StrategyReportStatusFromJSONTyped,
    StrategyReportStatusToJSON,
} from './StrategyReportStatus';

/**
 *
 * @export
 * @interface StrategyReportBasicInfoResponseDto
 */
export interface StrategyReportBasicInfoResponseDto {
    /**
     *
     * @type {string}
     * @memberof StrategyReportBasicInfoResponseDto
     */
    strategyReportId?: string;
    /**
     *
     * @type {string}
     * @memberof StrategyReportBasicInfoResponseDto
     */
    campaignId?: string;
    /**
     *
     * @type {string}
     * @memberof StrategyReportBasicInfoResponseDto
     */
    createdByUserId?: string;
    /**
     *
     * @type {string}
     * @memberof StrategyReportBasicInfoResponseDto
     */
    organisationId?: string;
    /**
     *
     * @type {StrategyReportStatus}
     * @memberof StrategyReportBasicInfoResponseDto
     */
    strategyReportStatus?: StrategyReportStatus;
}

/**
 * Check if a given object implements the StrategyReportBasicInfoResponseDto interface.
 */
export function instanceOfStrategyReportBasicInfoResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StrategyReportBasicInfoResponseDtoFromJSON(
    json: any,
): StrategyReportBasicInfoResponseDto {
    return StrategyReportBasicInfoResponseDtoFromJSONTyped(json, false);
}

export function StrategyReportBasicInfoResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): StrategyReportBasicInfoResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        strategyReportId: !exists(json, 'strategy_report_id')
            ? undefined
            : json['strategy_report_id'],
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        createdByUserId: !exists(json, 'created_by_user_id')
            ? undefined
            : json['created_by_user_id'],
        organisationId: !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        strategyReportStatus: !exists(json, 'strategy_report_status')
            ? undefined
            : StrategyReportStatusFromJSON(json['strategy_report_status']),
    };
}

export function StrategyReportBasicInfoResponseDtoToJSON(
    value?: StrategyReportBasicInfoResponseDto | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        strategy_report_id: value.strategyReportId,
        campaign_id: value.campaignId,
        created_by_user_id: value.createdByUserId,
        organisation_id: value.organisationId,
        strategy_report_status: StrategyReportStatusToJSON(value.strategyReportStatus),
    };
}
