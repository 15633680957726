import {
    CampaignStatus,
    CreateDraftCampaignRequestDto,
    RawImportedDataRequestDto,
    ScenarioAdFormatResponseDto,
} from '@api-clients/media-plan';
import { Box, Container, Flex, Heading, HStack, } from '@chakra-ui/react';
import { Loading } from '@components/atoms';
import { useAppContextHelper } from '@hooks/_contexts';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "@redux";
import { ErrorCard } from "@components/molecules/errorCard/ErrorCard";
import { GlobalCampaignDetails } from "@components/global/GlobalCampaignDetails";
import {
    availableGlobalCampaigns,
    globalCampaignIsError,
    globalCampaignIsPending,
    selectedGlobalCampaign
} from "@redux/slices/global/campaign/selectors";
import { useTranslation } from "react-i18next";
import { proveCampaignIsPending, proveSelectedCampaign } from "@redux/slices/prove/campaign/selectors";
import { routing } from "@configs";
import { createProveCampaignAsync } from "@redux/slices/prove/campaign/thunks";
import { useApiConfiguration } from "@hooks/configuration";
import { useCreateDraftCampaign } from "@hooks/campaigns";
import { BackButton } from "@apps/attention360/atoms";
import { findGlobalCampaignsAsync } from "@redux/slices/global/campaign/thunks";
import { CreateStrategyReportRequestDto } from '@api-clients/attention-data/models/CreateStrategyReportRequestDto';
import { useCreateDraftStrategyReport } from '@hooks/strategyReport/useCreateDraftStrategyReport';
import { StrategyReportStatus } from '@api-clients/attention-data/models/StrategyReportStatus';

interface FormData {
    campaignName: string;
    startDate: Date;
    endDate: Date;
    allocatedBudget: number;
}

export type SubTableDataType = ScenarioAdFormatResponseDto & { budget: number };
export type TableDataType = RawImportedDataRequestDto & {
    isChecked: boolean;
    adFormats?: SubTableDataType[];
};

export const GlobalCampaignDetailsPage: FC = () => {
    const dispatch = useDispatch();
    const { campaignId } = useParams();
    const { getProveManagementServiceConfig, getGlobalCampaignServerConfig } = useApiConfiguration();
    const availableGlobalCampaignsSelector = useSelector(availableGlobalCampaigns);
    const selectedGlobalCampaignSelector = useSelector(selectedGlobalCampaign);
    const campaignIsPendingSelector = useSelector(globalCampaignIsPending);
    const proveSelectedCampaignSelector = useSelector(proveSelectedCampaign);
    const proveCampaignIsPendingSelector = useSelector(proveCampaignIsPending);
    const campaignIsErrorSelector = useSelector(globalCampaignIsError);
    const [activateProductOnCreate, setActivateProductOnCreate] = useState<string | undefined>(undefined);
    const {
        currentContextValues: { organisation, user },
        helper: { selectGlobalCampaign }
    } = useAppContextHelper();
    const containerRef = useRef<HTMLDivElement>(null);
    const { createDraftCampaign } = useCreateDraftCampaign();
    const { createDraftStrategyReport } = useCreateDraftStrategyReport();

    const navigate = useNavigate();

    const { t } = useTranslation('campaign');

    const content = t('campaign.details', {
        returnObjects: true,
    });

    useEffect(() => {
        const fetchGlobalCampaigns = async () => {
            dispatch(findGlobalCampaignsAsync({
                configuration: await getGlobalCampaignServerConfig()
            }));
        }

        if (!availableGlobalCampaignsSelector) {
            fetchGlobalCampaigns().catch();
        }
    }, []);

    useEffect(() => {
        if (!availableGlobalCampaignsSelector || !campaignId) {
            return;
        }

        if (campaignId) {
            selectGlobalCampaign(campaignId).catch();
        }
    }, [availableGlobalCampaignsSelector, campaignId]);

    const activatePlanOnCreate = () => {
        setActivateProductOnCreate("PLAN");
    }

    const activateStrategyReportOnCreate = () => {
        setActivateProductOnCreate("STRATEGY_REPORT");
    }

    const activateProveOnCreate = () => {
        setActivateProductOnCreate("PROVE");
    }

    useEffect(() => {
        const isNewCampaign = !campaignId;

        if (!isNewCampaign || !selectedGlobalCampaignSelector?.id || !proveSelectedCampaignSelector?.id) {
            return;
        }

        navigate(`/${routing.prove.root.path}/${selectedGlobalCampaignSelector.id}/${routing.prove.manage.path}`);
    }, [selectedGlobalCampaignSelector, proveSelectedCampaignSelector]);

    useEffect(() => {
        const isNewCampaign = !campaignId;

        if (!isNewCampaign || !selectedGlobalCampaignSelector?.id) {
            return;
        }

        if (activateProductOnCreate === "PLAN") {
            const createMediaPlan = async () => {
                const payload: CreateDraftCampaignRequestDto = {
                    organisationId: organisation?.id,
                    campaignId: selectedGlobalCampaignSelector.id!,
                    campaignName: selectedGlobalCampaignSelector.name,
                    createdByUserId: user?.id,
                    managedByUserId: user?.id,
                    campaignStatus: CampaignStatus.Draft,
                    toCreateBaselineScenario: true,
                };

                const response = await createDraftCampaign(payload);

                navigate(`/${routing.plan.root.path}/${response.id}/${routing.plan.create.path}`);
            }

            createMediaPlan().catch();
        } else if (activateProductOnCreate === "PROVE") {
            const createProveCampaign = async () => {
                dispatch(createProveCampaignAsync({
                    configuration: await getProveManagementServiceConfig(),
                    campaignId: selectedGlobalCampaignSelector.id!
                }));
            }

            createProveCampaign().catch();
        } else if (activateProductOnCreate === "STRATEGY_REPORT") {
            const createStrategyReport = async () => {
                const payload: CreateStrategyReportRequestDto = {
                    name: selectedGlobalCampaignSelector.name,
                    campaignId: selectedGlobalCampaignSelector.id,
                    strategyReportStatus: StrategyReportStatus.Draft,
                };

                const response = await createDraftStrategyReport(payload);
                navigate(
                    `/${routing.campaign.root.path}/${response.campaignId}/${routing.strategyReports.root.path}/${routing.strategyReports.create.path}`,
                );
            }
            createStrategyReport().catch();
        }
    }, [selectedGlobalCampaignSelector, activateProductOnCreate]);

    return !campaignIsErrorSelector && organisation?.users ? (
        <>
            {!campaignIsPendingSelector && !proveCampaignIsPendingSelector && availableGlobalCampaignsSelector ? (
                <Container ref={containerRef}>
                    <HStack paddingBottom="1rem">
                        <BackButton />
                        <Heading>{campaignId ? content.manageHeading : content.createHeading}</Heading>
                    </HStack>

                    <Box overflow="clip" padding={0} boxShadow="xs" borderRadius="lg" bg="white">
                        {user && organisation ? (
                            <Flex h="100%" direction="column">
                                <GlobalCampaignDetails
                                    globalCampaigns={availableGlobalCampaignsSelector!}
                                    selectedCampaign={(campaignId) ? selectedGlobalCampaignSelector : undefined}
                                    activatePlanOnCreate={activatePlanOnCreate}
                                    activateProveOnCreate={activateProveOnCreate}
                                    activateStrategyReportOnCreate={activateStrategyReportOnCreate}
                                />
                            </Flex>
                        ) : (
                            <Loading />
                        )}
                    </Box>
                </Container>
            ) : (
                <Loading />
            )}
        </>
    ) : (
        <ErrorCard />
    );
};
