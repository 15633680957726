/* tslint:disable */
/* eslint-disable */
/**
 * Amplified Intelligence - Attention Data API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../runtime';
import type { AgeGroup } from './AgeGroup';
import { AgeGroupFromJSON, AgeGroupFromJSONTyped, AgeGroupToJSON } from './AgeGroup';
import type { CountryCodeIso2 } from './CountryCodeIso2';
import {
    CountryCodeIso2FromJSON,
    CountryCodeIso2FromJSONTyped,
    CountryCodeIso2ToJSON,
} from './CountryCodeIso2';
import type { Gender } from './Gender';
import { GenderFromJSON, GenderFromJSONTyped, GenderToJSON } from './Gender';
import type { SelectedAdFormatResponseDto } from './SelectedAdFormatResponseDto';
import {
    SelectedAdFormatResponseDtoFromJSON,
    SelectedAdFormatResponseDtoFromJSONTyped,
    SelectedAdFormatResponseDtoToJSON,
} from './SelectedAdFormatResponseDto';
import type { StrategyReportStatus } from './StrategyReportStatus';
import {
    StrategyReportStatusFromJSON,
    StrategyReportStatusFromJSONTyped,
    StrategyReportStatusToJSON,
} from './StrategyReportStatus';

/**
 *
 * @export
 * @interface StrategyReportResponseDto
 */
export interface StrategyReportResponseDto {
    /**
     *
     * @type {string}
     * @memberof StrategyReportResponseDto
     */
    strategyReportId?: string;
    /**
     *
     * @type {string}
     * @memberof StrategyReportResponseDto
     */
    campaignId?: string;
    /**
     *
     * @type {string}
     * @memberof StrategyReportResponseDto
     */
    name?: string | null;
    /**
     *
     * @type {Array<Gender>}
     * @memberof StrategyReportResponseDto
     */
    selectedGenders?: Array<Gender> | null;
    /**
     *
     * @type {Array<AgeGroup>}
     * @memberof StrategyReportResponseDto
     */
    selectedAgeGroups?: Array<AgeGroup> | null;
    /**
     *
     * @type {Array<SelectedAdFormatResponseDto>}
     * @memberof StrategyReportResponseDto
     */
    selectedFormats?: Array<SelectedAdFormatResponseDto> | null;
    /**
     *
     * @type {Array<CountryCodeIso2>}
     * @memberof StrategyReportResponseDto
     */
    selectedCountryCodes?: Array<CountryCodeIso2> | null;
    /**
     *
     * @type {boolean}
     * @memberof StrategyReportResponseDto
     */
    isUsingGlobalCountry?: boolean;
    /**
     *
     * @type {Date}
     * @memberof StrategyReportResponseDto
     */
    createdAt?: Date;
    /**
     *
     * @type {StrategyReportStatus}
     * @memberof StrategyReportResponseDto
     */
    strategyReportStatus?: StrategyReportStatus;
    /**
     *
     * @type {boolean}
     * @memberof StrategyReportResponseDto
     */
    isDeleted?: boolean;
}

/**
 * Check if a given object implements the StrategyReportResponseDto interface.
 */
export function instanceOfStrategyReportResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StrategyReportResponseDtoFromJSON(json: any): StrategyReportResponseDto {
    return StrategyReportResponseDtoFromJSONTyped(json, false);
}

export function StrategyReportResponseDtoFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): StrategyReportResponseDto {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        strategyReportId: !exists(json, 'strategy_report_id')
            ? undefined
            : json['strategy_report_id'],
        campaignId: !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        selectedGenders: !exists(json, 'selected_genders')
            ? undefined
            : json['selected_genders'] === null
            ? null
            : (json['selected_genders'] as Array<any>).map(GenderFromJSON),
        selectedAgeGroups: !exists(json, 'selected_age_groups')
            ? undefined
            : json['selected_age_groups'] === null
            ? null
            : (json['selected_age_groups'] as Array<any>).map(AgeGroupFromJSON),
        selectedFormats: !exists(json, 'selected_formats')
            ? undefined
            : json['selected_formats'] === null
            ? null
            : (json['selected_formats'] as Array<any>).map(SelectedAdFormatResponseDtoFromJSON),
        selectedCountryCodes: !exists(json, 'selected_country_codes')
            ? undefined
            : json['selected_country_codes'] === null
            ? null
            : (json['selected_country_codes'] as Array<any>).map(CountryCodeIso2FromJSON),
        isUsingGlobalCountry: !exists(json, 'is_using_global_country')
            ? undefined
            : json['is_using_global_country'],
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        strategyReportStatus: !exists(json, 'strategy_report_status')
            ? undefined
            : StrategyReportStatusFromJSON(json['strategy_report_status']),
        isDeleted: !exists(json, 'is_deleted') ? undefined : json['is_deleted'],
    };
}

export function StrategyReportResponseDtoToJSON(value?: StrategyReportResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        strategy_report_id: value.strategyReportId,
        campaign_id: value.campaignId,
        name: value.name,
        selected_genders:
            value.selectedGenders === undefined
                ? undefined
                : value.selectedGenders === null
                ? null
                : (value.selectedGenders as Array<any>).map(GenderToJSON),
        selected_age_groups:
            value.selectedAgeGroups === undefined
                ? undefined
                : value.selectedAgeGroups === null
                ? null
                : (value.selectedAgeGroups as Array<any>).map(AgeGroupToJSON),
        selected_formats:
            value.selectedFormats === undefined
                ? undefined
                : value.selectedFormats === null
                ? null
                : (value.selectedFormats as Array<any>).map(SelectedAdFormatResponseDtoToJSON),
        selected_country_codes:
            value.selectedCountryCodes === undefined
                ? undefined
                : value.selectedCountryCodes === null
                ? null
                : (value.selectedCountryCodes as Array<any>).map(CountryCodeIso2ToJSON),
        is_using_global_country: value.isUsingGlobalCountry,
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        strategy_report_status: StrategyReportStatusToJSON(value.strategyReportStatus),
        is_deleted: value.isDeleted,
    };
}
