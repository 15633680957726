import {
    StrategyReportResponseDto,
} from '@api-clients/attention-data';
import { UpdateDraftStrategyReportRequestDto } from '@api-clients/attention-data/models/UpdateDraftStrategyReportRequestDto';
import {
    StrategyReportsApi,
    UpdateADraftStrategyReportRequest
} from '@api-clients/attention-data/apis/StrategyReportsApi';
import { useApiConfiguration } from '@hooks/configuration';
import { useHelper } from '@shared/utils';

interface UpdateDraftStrategyReportHook {
    updateDraftStrategyReport: (
        strategyReportId: string,
        UpdateDraftStrategyReportRequestDto: UpdateDraftStrategyReportRequestDto,
    ) => Promise<StrategyReportResponseDto>;
}

type useUpdateDraftStrategyReportProps = () => UpdateDraftStrategyReportHook;

export const useUpdateDraftStrategyReport: useUpdateDraftStrategyReportProps = () => {
    const { getAttentionDataApiConfig } = useApiConfiguration();
    const { mapDisplayedErrorMessage } = useHelper();

    async function updateDraftStrategyReport(
        strategyReportId: string,
        updateDraftStrategyReportRequestDto: UpdateDraftStrategyReportRequestDto,
    ): Promise<StrategyReportResponseDto> {
        try {
            const config = await getAttentionDataApiConfig();
            const request: UpdateADraftStrategyReportRequest = {
                strategyReportId,
                updateDraftStrategyReportRequestDto,
            };

            const api = new StrategyReportsApi(config);
            return await api.updateADraftStrategyReport(request);
        } catch (e) {
            throw new Error(await mapDisplayedErrorMessage(e));
        }
    }

    return {
        updateDraftStrategyReport,
    };
};
