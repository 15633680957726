import { ChannelBenchmarkResponseDto } from '@api-clients/attention-data';
import { SortOption } from '@apps/attention360/atoms/sortOption/SortOption';
import {
    exportChannelSelectionSectionGraphToPng,
    exportChannelSelectionSectionGraphToXlsx,
    numberFormatter,
    SortOptionValue,
    CHANNEL_SELECTION_SORT_OPTIONS,
    useSortDisplayChannelData,
} from '@apps/attention360/pages';
import { ChannelSelectionGraph } from '@apps/attention360/pages/strategyReports/ChannelSelectionGraph';
import { ContainerTitle } from '@apps/attentionADJUST/components/atoms';
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Spacer,
    Spinner,
    Stack,
    Switch,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useBoolean,
    VStack,
} from '@chakra-ui/react';
import { AsyncCard } from '@components/atoms';
import { Column, DataTable, Row } from '@components/molecules';
import { useCustomToast } from '@hooks/toast';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import {
    ActiveAttentionTooltip,
    PassiveAttentionTooltip,
} from '@apps/attention360/pages/strategyReportTooltip';
import { StrategyReportNavigation } from '@apps/attention360/atoms';
import { routing } from '@configs';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { ConfigureAdFormatsButton } from './ConfigureAdFormatsButton';
import { ExportButton } from './ExportButton';
import { useStrategyReportContext } from './StrategyReportContextProvider';
import { useGetAllChannelBenchmarks } from '@hooks/strategyReport';

interface DataRow {
    channelId: string;
    channelCode: string;
    channelName: string;
    averageActiveAttention: number;
    averagePassiveAttention: number;
    activeAttentionPerImpressionCost: number;
    cpm: number;
    isSelected: boolean;
}

let columns: Array<Column<DataRow>> = [
    {
        title: 'Channel',
        dataIndex: 'channelName',
        isNumeric: false,
        key: 'channelId',
        render: (row) => {
            const baseStyle = {
                display: 'inline-block',
                marginRight: '8px',
            };

            const selectedDotStyle = {
                ...baseStyle,
                width: '8px',
                height: '8px',
                backgroundColor: '#00AC59',
                borderRadius: '50%',
            };

            const selectedTextStyle = {
                color: '#191919',
                fontWeight: 600,
            };

            const unselectedTextStyle = {
                color: '#4A4B4D',
                fontWeight: 500,
            };

            return (
                <>
                    <Flex alignItems="center">
                        {row.isSelected && <Text as="span" style={selectedDotStyle} />}
                        <Text
                            as="span"
                            style={row.isSelected ? selectedTextStyle : unselectedTextStyle}
                        >
                            {row.channelName}
                        </Text>
                    </Flex>
                </>
            );
        },
    },
    {
        title: 'Active attention (s)',
        dataIndex: 'averageActiveAttention',
        isNumeric: true,
        key: 'active',
        render: (row) => {
            return <>{numberFormatter.format(row.averageActiveAttention)}</>;
        },
        tooltip: <ActiveAttentionTooltip />,
    },
    {
        title: 'Passive attention (s)',
        dataIndex: 'averagePassiveAttention',
        isNumeric: true,
        key: 'passive',
        render: (row) => {
            return <>{numberFormatter.format(row.averagePassiveAttention)}</>;
        },
        tooltip: <PassiveAttentionTooltip />,
    },
    {
        title: 'Active attention per impression cost (s)',
        dataIndex: 'activeAttentionPerImpressionCost',
        isNumeric: true,
        key: 'inactive',
        render: (row) => {
            return <>{numberFormatter.format(row.activeAttentionPerImpressionCost)}</>;
        },
    },
    {
        title: 'CPM',
        dataIndex: 'cpm',
        isNumeric: true,
        key: 'cpm',
        render: (row) => {
            return (
                <Flex justifyContent="space-between" alignItems="center" gap="1rem">
                    <Text as="span" color="gray.500">
                        $
                    </Text>
                    <Text as="span">{numberFormatter.format(row.cpm)}</Text>
                </Flex>
            );
        },
    },
];

export const ChannelSelectionSection: FC = () => {
    const { strategyReport } = useStrategyReportContext();
    const { getAllChannelBenchmarks } = useGetAllChannelBenchmarks();
    const [isLoading, setIsLoading] = useState(false);
    const { errorToast } = useCustomToast();
    const emitViewChannelSelectionSectionEvent = usePosthogEvent(
        'View strategy report channel selection section',
    );
    useEffect(() => {
        emitViewChannelSelectionSectionEvent();
    }, []);

    const chartRef = useRef<ChartJSOrUndefined<'bar', string[], object>>();
    const [channelSelectionSortBy, setChannelSelectionSortBy] = useState<SortOptionValue>('active');
    const [showSelectedChannelOnly, setShowSelectedChannelOnly] = useBoolean(false);
    const [channelBenchmarkData, setChannelBenchmarkData] =
        useState<ChannelBenchmarkResponseDto[]>();

    const sortDisplayedData = useSortDisplayChannelData();
    const displayedChannelBenchmarkData = useMemo(() => {
        return sortDisplayedData(
            channelBenchmarkData ?? [],
            channelSelectionSortBy,
            showSelectedChannelOnly,
        );
    }, [channelBenchmarkData, showSelectedChannelOnly, channelSelectionSortBy]);
    const handleExportToPng = async () => {
        try {
            await exportChannelSelectionSectionGraphToPng(chartRef, strategyReport.name);
        } catch (e: any) {
            errorToast('Oops', 'Something went wrong when exporting PNG');
        }
    };
    const handleExportToXlsx = async () => {
        try {
            await exportChannelSelectionSectionGraphToXlsx(
                displayedChannelBenchmarkData,
                strategyReport?.name,
            );
        } catch (e: any) {
            errorToast('Oops', 'Something went wrong when exporting XLSX');
        }
    };
    const dataSource: Array<Row<DataRow>> =
        displayedChannelBenchmarkData?.map(
            (item): Row<DataRow> => ({
                key: item.channelId ?? '',
                channelId: item.channelId ?? '',
                channelCode: item.channelCode ?? '',
                channelName: item.channelName ?? '',
                averageActiveAttention: item.averageActiveAttention ?? 0,
                averagePassiveAttention: item.averagePassiveAttention ?? 0,
                activeAttentionPerImpressionCost:
                    item.cpm && item.averageActiveAttention
                        ? item.averageActiveAttention / item.cpm
                        : 0,
                cpm: item.cpm ?? 0,
                isSelected: item.isSelected ?? false,
            }),
        ) ?? [];

    useEffect(() => {
        setIsLoading(true);
        getAllChannelBenchmarks({
            strategyReportId: strategyReport.strategyReportId!,
        })
            .then((r) => setChannelBenchmarkData(r))
            .finally(() => setIsLoading(false));
    }, [strategyReport]);

    // show graph view by default
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <>
            <Flex width="100%">
                <ContainerTitle
                    headingText="Channel selection"
                    subtitleText="We have ranked media channels based on attention seconds performance and cost efficiency for your campaign, tailored to your targeted demographics"
                />
                <Spacer />
                <VStack align="end">
                    <Stack direction="row">
                        <ExportButton
                            onClick={async (e) => {
                                if (tabIndex === 0) {
                                    await handleExportToXlsx();
                                } else {
                                    await handleExportToPng();
                                }
                            }}
                        />
                    </Stack>
                    <Box>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel mb="0">Show only selected channels</FormLabel>
                            <Switch
                                isChecked={showSelectedChannelOnly}
                                onChange={setShowSelectedChannelOnly.toggle}
                            />
                        </FormControl>
                    </Box>
                </VStack>
            </Flex>
            <Tabs onChange={(index) => setTabIndex(index)} colorScheme="orange" index={tabIndex}>
                <Flex justifyContent="space-between" alignItems="flex-end" gap="2rem">
                    {/**  Three radio buttons */}
                    <Flex gap=".5rem" alignItems="center">
                        {CHANNEL_SELECTION_SORT_OPTIONS.map((option) => {
                            return (
                                <SortOption
                                    key={option.value}
                                    value={option.value}
                                    heading={option.heading}
                                    description={option.description}
                                    selectedSortOption={channelSelectionSortBy}
                                    setSelectedSortOption={(value) =>
                                        setChannelSelectionSortBy(value)
                                    }
                                    tooltip={option.tooltip}
                                />
                            );
                        })}
                    </Flex>
                    <TabList>
                        {/** table view/Graph view tabs */}
                        <Tab>Graph view</Tab>
                        <Tab>Table view</Tab>
                    </TabList>
                </Flex>
                <TabPanels>
                    <TabPanel px={0}>
                        {/** Graph view */}
                        <Flex
                            flexDir="column"
                            gap="1rem"
                            p="2rem"
                            bg="white"
                            border="1px solid"
                            borderColor="gray.200"
                            borderRadius="0.375rem"
                        >
                            {isLoading ? (
                                <Box minH="24rem" display="grid" placeItems="center">
                                    <Spinner size="xl" />
                                </Box>
                            ) : (
                                <Box maxHeight="48rem" minHeight="36rem">
                                    <ChannelSelectionGraph
                                        ref={chartRef}
                                        channelBenchmark={displayedChannelBenchmarkData}
                                    />
                                </Box>
                            )}
                        </Flex>
                    </TabPanel>
                    <TabPanel px={0}>
                        <AsyncCard isLoading={isLoading}>
                            <DataTable dataSource={dataSource} columns={columns} />
                        </AsyncCard>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {!isLoading && (
                <Box>
                    <StrategyReportNavigation
                        next={`../${routing.strategyReports.view.formatSelection.path}`}
                    />
                </Box>
            )}
        </>
    );
};
