import { NavBarContainer } from '@components/organisms';
import { FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AttentionPlanAppProvider } from '@hooks/_contexts';
import { useAuth0 } from '@auth0/auth0-react';
import { useGoogleAnalytics } from '@hooks/analytics/useGoogleAnalytics';
import { CookieBanner, initialiseHotjar } from '@components/pages/cookieBanner/CookieBanner';
import { initialiseMicrosoftClarity } from '@components/pages/cookieBanner/initialiseMicrosoftClarity';
import { removePerformanceCookies } from '@shared/utils/helper';
import {
    getPersistedCookieAcceptanceState,
    persistCookieAcceptance,
} from '@states/cookieAcceptanceState';
import { getPersistedCookieBannerCompletionState } from '@states/cookieBannerCompletionState';
import posthog from 'posthog-js';
import { useLocalStorage } from '@hooks/localStorage';
import { CacheKey } from '@shared/cores';
import {
    OrganisationResponseDtoFromJSON,
    UserResponseDtoFromJSON,
} from '@api-clients/account-manager';

export const initialisePostHog = () => {
    posthog.init(import.meta.env.VITE_APP_POSTHOG_ID, {
        api_host: import.meta.env.VITE_APP_POSTHOG_API_HOST,
    });
    const toolbarJSON = new URLSearchParams(window.location.hash.substring(1)).get('__posthog');
    if (toolbarJSON) {
        posthog.loadToolbar(JSON.parse(toolbarJSON));
    }
};

const postHogAutoCaptureAttribute = {
    userEmail: 'data-ph-capture-attribute-user_email',
    userName: 'data-ph-capture-attribute-user_name',
    userId: 'data-ph-capture-attribute-user_id',
    orgId: 'data-ph-capture-attribute-organisation_id',
    orgName: 'data-ph-capture-attribute-organisation_name',
};
export const NavBarLayout: FC = () => {
    const { user } = useAuth0();
    const location = useLocation();

    const googleAnalytics = useGoogleAnalytics();

    useEffect(() => {
        googleAnalytics.initialise();
        initialiseHotjar();
        if (
            !window.location.host.includes('127.0.0.1') &&
            !window.location.host.includes('localhost')
        ) {
            initialiseMicrosoftClarity();
            initialisePostHog();
        }
    }, []);

    const [localStorageUser, _unusedSetUser] = useLocalStorage(CacheKey.User);
    const localUser = UserResponseDtoFromJSON(localStorageUser);
    const [localStorageOrg, _unusedSetOrg] = useLocalStorage(CacheKey.Organisation);
    const localOrg = OrganisationResponseDtoFromJSON(localStorageOrg);
    useEffect(() => {
        posthog.capture('$pageview', {
            [postHogAutoCaptureAttribute.orgName]: localOrg?.organisationName ?? 'N/A',
            [postHogAutoCaptureAttribute.orgId]: localOrg?.id ?? 'N/A',
            [postHogAutoCaptureAttribute.userId]: localUser?.id ?? 'N/A',
            [postHogAutoCaptureAttribute.userEmail]: localUser?.emailAddress ?? 'N/A',
            [postHogAutoCaptureAttribute.userName]: `${localUser?.firstName ?? ''} ${
                localUser?.lastName ?? ''
            }`.trim(),
        });
    }, [location]);

    useEffect(() => {
        document.body.setAttribute(
            postHogAutoCaptureAttribute.userEmail,
            localUser.emailAddress ?? 'N/A',
        );
        document.body.setAttribute(
            postHogAutoCaptureAttribute.userName,
            `${localUser?.firstName ?? ''} ${localUser?.lastName ?? ''}`.trim(),
        );
        document.body.setAttribute(postHogAutoCaptureAttribute.userId, localUser?.id ?? 'N/A');
        document.body.setAttribute(postHogAutoCaptureAttribute.orgId, localOrg?.id ?? 'N/A');
        document.body.setAttribute(
            postHogAutoCaptureAttribute.orgName,
            localOrg?.organisationName ?? 'N/A',
        );
    }, []);
    return (
        <>
            {user && (
                <AttentionPlanAppProvider>
                    <NavBarContainer>
                        <Outlet />
                    </NavBarContainer>
                </AttentionPlanAppProvider>
            )}
        </>
    );
};
