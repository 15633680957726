import {
    Button,
    Center,
    HStack,
    Image,
    Link,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    UnorderedList,
    useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routing } from '@configs';
import { GetFullVersionModal } from '../getFullVersionModal/GetFullVersionModal';

export interface ProveAnnouncementModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const announcementText = {
    title: 'Exciting new strategy feature now available!',
    keyBenefits: [
        {
            id: 0,
            title: 'Optimal channel & format selection',
            description:
                'Discover the most attentive channels and ad formats to help deliver campaign objectives.',
        },
        {
            id: 1,
            title: 'Audience analysis',
            description:
                'Dive into the nuances of audience groups to see how attention differs across the media channels in your mix.',
        },
        {
            id: 2,
            title: 'Maximise creative impact',
            description:
                'Use attention data to optimise your creative assets to fuel performance for your brand.',
        },
    ],
};
export const AnnouncementModal: FC<ProveAnnouncementModalProps> = ({ isOpen, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const navigate = useNavigate();

    const {
        onOpen: onOpenFullVersionModal,
        onClose: onCloseFullVersionModal,
        isOpen: isFullVersionModalOpen,
    } = useDisclosure();

    return (
        <>
            <Modal size="4xl" isOpen={isOpen} onClose={handleClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>{announcementText.title}</ModalHeader>
                    <ModalBody>
                        <Stack>
                            <Text>
                                attentionPLAN just got more powerful with our new strategy report
                                feature, so you can easily utilise human attention data in your
                                campaign strategies. Allowing you to brief media and creative teams
                                with the attention insights that are best suited to deliver campaign
                                success. Features include;
                            </Text>
                            <Text>Features include;</Text>
                            <UnorderedList display="flex" flexDir="column" gap=".25rem">
                                {announcementText.keyBenefits.map((item) => (
                                    <ListItem key={item.id}>
                                        <Text as="span" fontWeight={700}>
                                            {item.title}
                                        </Text>
                                        <Text as="span" fontWeight={700}>{`: `}</Text>
                                        <Text as="span">{item.description}</Text>
                                    </ListItem>
                                ))}
                            </UnorderedList>
                            <Image
                                marginBottom="1rem"
                                marginX="10rem"
                                src="/strategy_report_example.png"
                                alt="strategy report example"
                            />
                            <Text>
                                attentionPLAN’s new strategy report is a powerful tool ready to help
                                you level-up campaign performance.
                            </Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button
                                variant="outline"
                                type="button"
                                onClick={() => {
                                    handleClose();
                                    onOpenFullVersionModal();
                                }}
                            >
                                Buy attentionPLAN
                            </Button>
                            <Button
                                variant="solid"
                                onClick={() => {
                                    handleClose();
                                    navigate(
                                        `/${routing.campaign.root.path}/${routing.campaign.create.path}`,
                                    );
                                }}
                            >
                                Create Report
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <GetFullVersionModal
                isOpen={isFullVersionModalOpen}
                onClose={onCloseFullVersionModal}
            />
        </>
    );
};
