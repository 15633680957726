import { GlobalCampaign } from '@api-clients/global/campaign/schema/GlobalCampaign';
import { FeatureCode, LimitType } from '@api-clients/subscriptions';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    Stack,
    useDisclosure
} from '@chakra-ui/react';
import { GetFullVersionModal } from '@components/molecules';
import { DateRange, DateRangeFormParam } from '@components/molecules/dateRange/DateRange';
import { EnableProveModal } from '@components/molecules/modals/enableProveModal/EnableProveModal';
import { routing } from '@configs';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useApiConfiguration } from '@hooks/configuration';
import { useAppContextHelper } from '@hooks/_contexts';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';
import { useDispatch } from '@redux';
import {
    createGlobalCampaignAsync,
    updateGlobalCampaignAsync
} from '@redux/slices/global/campaign/thunks';
import moment from 'moment';
import { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CampaignTypeRadio, CampaignTypeRadioProps } from './CampaignTypeRadio';

interface FormData {
    name: string;
    startDate: Date;
    endDate: Date;
    allocatedBudget: number;
}

export interface CampaignDetailsProps {
    globalCampaigns: GlobalCampaign[];
    selectedCampaign: GlobalCampaign | undefined;
    activatePlanOnCreate: () => void;
    activateProveOnCreate: () => void;
    activateStrategyReportOnCreate: () => void;
}

enum CampaignType {
    Prove,
    Strategy,
    Optimise,
}

export const GlobalCampaignDetails: FC<CampaignDetailsProps> = ({
    globalCampaigns,
    selectedCampaign,
    activatePlanOnCreate,
    activateProveOnCreate,
    activateStrategyReportOnCreate
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getGlobalCampaignServerConfig } = useApiConfiguration();

    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFormParam>({
        startDate:
            selectedCampaign && !!selectedCampaign.startDate
                ? moment.utc(selectedCampaign.startDate).toDate()
                : null,
        endDate:
            selectedCampaign && !!selectedCampaign.endDate
                ? moment.utc(selectedCampaign.endDate).toDate()
                : null,
        isValid:
            selectedCampaign !== undefined &&
            !!selectedCampaign.startDate! &&
            !!selectedCampaign.endDate!,
    });

    const { t } = useTranslation('campaign');
    const content = t('campaign.details', {
        returnObjects: true,
    });

    const containerRef = useRef<HTMLDivElement>(null);

    // TODO: how is Posthog used and do we need to setup events for each user interaction?
    const emitPosthogCreateCampaignEvent = usePosthogEvent('Create new campaign');
    const emitPosthogUpdateCampaignEvent = usePosthogEvent('Update existing campaign');

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormData>({
        defaultValues: {
            name: selectedCampaign?.name,
        },
        mode: 'onChange',
    });

    const onCreateStrategyReport = async (data: FormData) => {
        const configuration = await getGlobalCampaignServerConfig();

        const formattedStartDate = moment.utc(selectedDateRange.startDate!).format('YYYY-MM-DD');
        const formattedEndDate = moment.utc(selectedDateRange.endDate!).format('YYYY-MM-DD');

        const campaign = await dispatch(
            createGlobalCampaignAsync({
                configuration,
                campaign: {
                    name: data.name,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                },
            }),
        );
        if (typeof campaign.payload !== 'object' || !campaign.payload?.id) {
            // TODO: toast error
            return;
        }
        const newGlobalCampaignId = campaign.payload.id;
        navigate(
            `/${routing.campaign.root.path}/${newGlobalCampaignId}/${routing.strategyReports.root.path}/${routing.strategyReports.create.path}`,
        );
    };

    const onFormSave = async (data: FormData) => {
        const configuration = await getGlobalCampaignServerConfig();

        const formattedStartDate = moment.utc(selectedDateRange.startDate!).format('YYYY-MM-DD');
        const formattedEndDate = moment.utc(selectedDateRange.endDate!).format('YYYY-MM-DD');

        if (
            !!selectedCampaign?.name &&
            !!selectedCampaign?.startDate &&
            !!selectedCampaign?.endDate
        ) {
            const needToUpdateCampaign =
                selectedCampaign.name !== data.name ||
                selectedCampaign.startDate !== formattedStartDate ||
                selectedCampaign.endDate !== formattedEndDate;

            if (needToUpdateCampaign) {
                dispatch(
                    updateGlobalCampaignAsync({
                        configuration,
                        campaign: {
                            ...selectedCampaign,
                            name: data.name,
                            startDate: formattedStartDate,
                            endDate: formattedEndDate,
                        },
                    }),
                );

                emitPosthogUpdateCampaignEvent();

                navigate(`/${routing.campaign.root.path}`);
            }
        } else {
            dispatch(
                createGlobalCampaignAsync({
                    configuration,
                    campaign: {
                        name: data.name,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                    },
                }),
            );

            emitPosthogCreateCampaignEvent();
        }
    };

    const isFormInvalid = !selectedDateRange.isValid || !isValid;

    const {
        currentContextValues: { featureBalances },
    } = useAppContextHelper();

    const {
        isOpen: isUpgradeOptimiserModalOpen,
        onOpen: onUpgradeOptimiserModalOpen,
        onClose: onUpgradeOptimiserModalClose,
    } = useDisclosure();

    const {
        isOpen: isEnableProveModalOpen,
        onOpen: onEnableProveModalOpen,
        onClose: onEnableProveModalClose,
    } = useDisclosure();

    const createMediaPlanFeature = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.MediaPlansCreateMediaPlan,
    );

    const canUserCreateCampaigns =
        createMediaPlanFeature && createMediaPlanFeature.limit! - createMediaPlanFeature.used! > 0;

    const canUserEnableProve =
        featureBalances?.find((f) => f.featureCode === FeatureCode.ProveSaasAccess) !== undefined;
    const createStrategyReportFeature = featureBalances?.find(
        (f) => f.featureCode === FeatureCode.StrategyReportCreateReport,
    );
    const canCreateStrategyReport = createStrategyReportFeature
        ? createStrategyReportFeature.limit! - createStrategyReportFeature.used! > 0
        : false;

    const isPaidPlan =
        featureBalances?.find(
            (f) =>
                f.featureCode === FeatureCode.MediaPlansViewScenario &&
                f.limitType === LimitType.Unlimited,
        ) ?? false;

    function isUniqueName(value: string) {
        if (selectedCampaign && selectedCampaign.name === value) {
            return true;
        }

        for (let i = 0; i < globalCampaigns.length; i++) {
            if (globalCampaigns[i].name === value) {
                return false;
            }
        }

        return true;
    }

    const campaignOptions: CampaignTypeRadioProps<CampaignType>[] = [
        {
            value: CampaignType.Strategy,
            productName: 'attentionPLAN',
            heading: 'Create a strategy report',
            isDisabled: !canCreateStrategyReport,
            description:
                'Build an attention optimised strategy report to help you deliver campaign goals and reduce media spending wastage.',
            onLearnMore: onUpgradeOptimiserModalOpen,
        },
        {
            value: CampaignType.Optimise,
            productName: 'attentionPLAN',
            heading: 'Optimise media spend',
            isDisabled: !canUserCreateCampaigns,
            description:
                'Already got a media plan? Fine tune your plan to optimise ROI using attention data.',
            onLearnMore: onUpgradeOptimiserModalOpen,
        },
        {
            value: CampaignType.Prove,
            productName: 'attentionPROVE',
            heading: 'Verify campaign performance',
            isDisabled: !canUserEnableProve,
            description:
                'Measure in-flight campaign performance and make human-led decisions that maximise ROAS.',
            onLearnMore: onEnableProveModalOpen,
            onViewDemo: () => navigate(`/${routing.prove.root.path}/${routing.prove.demo.path}`),
        },
    ];

    const [selectedOption, setSelectedOption] = useState<CampaignType | undefined>(
        campaignOptions.find((o) => !o.isDisabled)?.value,
    );

    return (
        <>
            <GetFullVersionModal
                isOpen={isUpgradeOptimiserModalOpen}
                onClose={onUpgradeOptimiserModalClose}
            />
            <EnableProveModal
                key="EnableProveModal_GlobalCampaignDetails"
                isOpen={isEnableProveModalOpen}
                onClose={onEnableProveModalClose}
            />
            <form>
                <Box paddingX="3rem" paddingY="2rem">
                    <Flex flexDirection="column" gap="1rem" maxW="42rem" mx="auto">
                        <Box>
                            <FormControl isRequired isInvalid={errors.name !== undefined}>
                                <FormLabel>{content.form.name.label}</FormLabel>
                                <Input
                                    {...register('name', {
                                        required: {
                                            value: true,
                                            message: content.form.name.requiredMessage,
                                        },
                                        validate: (value) =>
                                            isUniqueName(value.trim())
                                                ? true
                                                : content.form.name.duplicateMessage,
                                        maxLength: {
                                            value: 100,
                                            message: content.form.name.errorMessage,
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.name && (errors.name.message as string)}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <DateRange
                            dateRangeForm={selectedDateRange}
                            onChange={setSelectedDateRange}
                            startDateRequired
                            endDateRequired
                        />

                        {selectedCampaign?.id ? (
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={() => {
                                        containerRef.current?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'start',
                                        });

                                        handleSubmit(onFormSave)();
                                    }}
                                    rightIcon={<Icon as={ArrowRightIcon} />}
                                    isDisabled={isFormInvalid}
                                    type="submit"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {content.saveButton}
                                </Button>
                            </Box>
                        ) : (
                            <Stack>
                                <Flex direction="column">
                                    <FormControl isRequired>
                                        <FormLabel>Where would you like to start?</FormLabel>
                                        <Stack>
                                            {campaignOptions.map((o) => (
                                                <CampaignTypeRadio
                                                    onClick={() => setSelectedOption(o.value)}
                                                    isChecked={o.value === selectedOption}
                                                    {...o}
                                                />
                                            ))}
                                        </Stack>
                                    </FormControl>
                                </Flex>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        onClick={() => {
                                            if (selectedOption === CampaignType.Optimise) {
                                                activatePlanOnCreate();
                                                handleSubmit(onFormSave)();
                                            }
                                            if (selectedOption === CampaignType.Prove) {
                                                activateProveOnCreate();
                                                handleSubmit(onFormSave)();
                                            }
                                            if (selectedOption === CampaignType.Strategy) {
                                                activateStrategyReportOnCreate();
                                                handleSubmit(onCreateStrategyReport)();
                                            }
                                        }}
                                        rightIcon={<Icon as={ArrowRightIcon} />}
                                        isDisabled={isFormInvalid || selectedOption === undefined}
                                        type="submit"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        Create
                                    </Button>
                                </Box>
                            </Stack>
                        )}
                    </Flex>
                </Box>
            </form>
        </>
    );
};

